import { ModalDataSavedError as Modal } from '@/components/layout/ModalDataSaved';
import { UIBox } from '@/components/ui/Box';
import { UIButton } from '@/components/ui/Button';
import {
  setShowSessionExpiredModal,
} from '@/features/expiringSession/sessionExpired';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

interface SessionExpiredProps {}

const SessionExpiredModal: FC<SessionExpiredProps> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { showSessionExpiredModal } = useSelector(
    state => state.sessionExpired
  );

  const closeModal = (): void => {
    dispatch(setShowSessionExpiredModal(false));
  };

  return showSessionExpiredModal ? (
    <Modal
      iconType="ERROR"
      $minWidth="500px"
      $minHeight="250px"
      title={t('sessionExpired.title')}
      message={t('sessionExpired.message')}
      open={showSessionExpiredModal}
      onClose={(): void => closeModal()}
    >
      <UIBox mt={4} alignItems="center" width="30%" alignSelf="center">
        <UIButton
          outlined
          label={t('sessionExpired.action')}
          onClick={(): void => closeModal()}
        />
      </UIBox>
    </Modal>
  ) : (
    <></>
  );
};

export default SessionExpiredModal;
