import { CalendarIcon } from '@/pages/CycleCount/PageSoh/style';
import type { ListProps } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { DateTime } from 'luxon';
import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import styled from 'styled-components';

export interface UIListProps extends ListProps {
  data: string[];
}

const StyledList = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem 0;
`;

const StyledItem = styled('div')`
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  box-shadow: 0px -1px 8px 1px rgba(0, 0, 0, 0.05);

  padding: 1rem;
  display: flex;
  align-items: center;

  cursor: pointer;
`;

const StyledTitle = styled(Typography)``;

const RepricingList: FC<UIListProps> = memo(
  ({ data }: UIListProps): JSX.Element => {
    const { t } = useTranslation();
    const history = useHistory();

    const handleClick = (date: string) => {
      history.push(history.location.pathname + '/' + date);
    };

    return (
      <StyledList>
        <StyledTitle>{t('repricing.datesTitle')}</StyledTitle>
        {data.map(date => (
          <StyledItem onClick={() => handleClick(date)}>
            <CalendarIcon />
            {String(
              DateTime.fromFormat(String(date), 'yyyyMMdd').toFormat(
                'yyyy MMMM dd'
              )
            )}
          </StyledItem>
        ))}
      </StyledList>
    );
  }
);

RepricingList.displayName = 'List';

export default RepricingList;
