import { UrlNotificationRepricing } from '@/pages/Repricing/types';
import { makeUrl } from '@/utils/links';
import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { useAsync } from 'react-use';
import { useSelector } from '@/hooks/useSelector';
import { useNotificationContext } from '@/context/notificationContext';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import useCalendar from '@/hooks/useCalendar';

import { UIBox } from '@/components/ui/Box';
import { Typography } from '@/components/ui/Typography';
import { UICheckbox } from '@/components/ui/Checkbox';
import { UIButton } from '@/components/ui/Button';
import { UICalendar } from '@/components/ui/Calendar';
import { UILoader } from '@/components/ui/Loader';
import { ErrorSnackbar } from '@/components/ui/ErrorSnackbar';
import { setCurrentDateSlice } from '@/features/repricing/repriceSlice';

import { DateTime } from 'luxon';
import { colors, theme } from '@/theme';
import {
  NotificationDetailsResponse as Notification,
  NotificationService,
} from '@/api/receive';
import type { Process } from '@/types/notification';
import { NOTIFICATION } from '@/types/enum';
import { AppRoutes } from '@/app/routers';
import { useAppDispatch } from '@/app/store';
import { userHasAllPermission } from '@/utils/user';

//#region - Styled Components
const StyledWrapper = styled(UIBox)`
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;

  ${theme.breakpoints.down(769)} {
    flex-direction: column;
  }
`;

const StyledTextButton = styled(UIButton)<{ disabled?: boolean }>`
  &&& {
    width: fit-content;
    text-transform: none;
    margin-right: 24px;
    background: transparent;
    box-shadow: none;
    border: none;
    color: ${({ disabled }): string =>
      disabled ? 'grey' : `${colors.primary}`};

    span {
      text-decoration: underline;
    }
  }
`;

const StyledContainer = styled(UIBox)`
  width: 50%;

  ${theme.breakpoints.down(769)} {
    width: 100%;
  }
`;

const StyledDecorator = styled('div')`
  width: 5px;
  border-radius: 100px;
  background: ${colors.primary};
  margin-right: 8px;
`;

const StyledNotiticationWrapper = styled(UIBox)`
  margin-top: 12px;
  align-items: center;
  justify-content: space-between;
`;

const StyledNotiticationInfo = styled(UIBox)`
  padding: 4px 0;

  & > span {
    display: block;

    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
`;

const StyledNotiticationButton = styled(UIButton)`
  width: fit-content;
`;

const StyledDate = styled(Typography)`
  text-transform: capitalize;
`;

export const StyledUILoaderWrapper = styled.div`
  margin-top: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
//#endregion

export const getNotificationInfo = (
  dateNotification: string
): {
  day: number;
  month: number;
  year: number;
} => {
  const day = Number(dateNotification.split('/')[2]);
  const month = Number(dateNotification.split('/')[1]);
  const year = Number(dateNotification.split('/')[0]);

  return {
    day,
    month,
    year,
  };
};

const PageNotification: React.FC = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const [currentDate, setCurrentDate] = useState<Date>(new Date());
  const [events, setEvents] = useState<Notification[]>([]);
  const [filteredEvents, setFilteredEvents] = useState<Notification[]>([]);
  const [filterIsDisabled, setFilterDisabled] = useState<boolean>(false);
  const [loadingEvents, setLoadingEvents] = useState<boolean>(false);
  const [snackbarIsVisible, setSnackbarVisibility] = useState<boolean>(false);
  const [seeCurrentMonthIsDisabled, setCurrentMonthIsDisabled] =
    useState<boolean>(true);
  const [processTypes, setProcessTypes] = useState<Process[]>(
    Object.values(NOTIFICATION)
  );
  const [fetchNotification, setFetchNotification] = useState<boolean>(true);

  const dispatch = useAppDispatch();

  const {
    findEventPerMonth,
    findEventPerDay,
    date: { currentMonth, setCurrentDay, setCurrentMonth, setCurrentYear },
  } = useCalendar();

  const { setNotificationAvailability } = useNotificationContext();
  const storeCode = useSelector(state => state.currentStore.store?.storeCode);

  useAsync(async () => {
    if (storeCode && currentDate) {
      const selectedYear = currentDate.getFullYear();
      const selectedMonth = currentDate.getMonth() + 1;

      const lastDay = new Date(selectedYear, selectedMonth, 0).getDate();
      const month = String(selectedMonth).padStart(2, '0');
      const dateFrom = `${selectedYear}${month}01`;
      const dateTo = `${selectedYear}${month}${lastDay}`;

      try {
        setLoadingEvents(true);

        const response =
          await NotificationService.notificationRetriveNotificationsDetails({
            storeCode,
            dateFrom,
            dateTo,
          });

        if (response?.notificationDetails) {
          setEvents(response.notificationDetails);
        }

        setLoadingEvents(false);
      } catch {
        setSnackbarVisibility(true);
      }
    }
  }, [currentDate]);

  useEffect(() => {
    setNotificationAvailability(false);
  }, [setNotificationAvailability]);

  const eventsPerMonth = useMemo(
    () =>
      events.filter(({ dateNotification, typeProcess }) => {
        const { month, year } = getNotificationInfo(dateNotification);

        if (processTypes.length > 0) {
          return (
            findEventPerMonth(month, year) && processTypes.includes(typeProcess)
          );
        }

        return findEventPerMonth(month, year);
      }),
    [events, findEventPerMonth, processTypes]
  );

  const hasEventsForToday = useMemo(
    () =>
      events.filter(({ dateNotification, typeProcess }) => {
        const { month, year, day } = getNotificationInfo(dateNotification);

        const hasEvents = findEventPerDay(day, month, year);
        if (processTypes.length > 0) {
          return hasEvents && processTypes.includes(typeProcess);
        }

        return hasEvents;
      }).length > 0,
    [events, findEventPerDay, processTypes]
  );

  useEffect(() => {
    setFilteredEvents(eventsPerMonth);
  }, [currentMonth, eventsPerMonth]);

  const processChangeHandler = (process: Process): void => {
    setProcessTypes(prevState => {
      if (prevState.includes(process)) {
        return prevState.filter(p => !p.includes(process));
      }

      if (process === NOTIFICATION.INVENTORY) {
        return [...prevState, process, NOTIFICATION.INVENTORYP];
      }

      return [...prevState, process];
    });
  };

  const calendarDayClickHandler = (
    date?: Date,
    eventsPerDay?: Notification[]
  ): void => {
    if (date && eventsPerDay) {
      setCurrentDay(date.getDate());
      if (!filterIsDisabled) {
        setFilterDisabled(true);
        setFilteredEvents(eventsPerDay);
      } else {
        setFilterDisabled(false);

        /**
         * Day already selected show month events
         */
        if (eventsPerDay.length > 0) {
          setCurrentMonth(date.getMonth() + 1);
          setCurrentYear(date.getFullYear());
          setFilteredEvents(eventsPerMonth);
        }
      }
    }
  };

  const calendarChangeDayHandler = (
    date?: Date,
    eventsPerDay?: Notification[]
  ): void => {
    if (eventsPerDay && date) {
      setCurrentDay(date.getDate());
      if (!filterIsDisabled && date !== currentDate) {
        setFilterDisabled(true);
      }

      if (eventsPerDay.length === 0) {
        return;
      }

      setFilteredEvents(eventsPerDay);
    }
  };

  const calendarChangeMonthHandler = (
    date?: Date,
    eventsPerMonth?: Notification[]
  ): void => {
    if (date && (fetchNotification || currentDate !== date) && eventsPerMonth) {
      setCurrentDate(date);
      setFilteredEvents(eventsPerMonth);
      if (currentDate !== date) {
        setCurrentMonthIsDisabled(false);
      }
    }
  };

  const seeTodayClickHandler = (): void => {
    setCurrentMonthIsDisabled(true);
    setFetchNotification(prev => !prev);
    setFilterDisabled(prev => !prev);
    setCurrentDate(new Date());
  };

  const seeCurMonthClickHandler = (): void => {
    setCurrentMonthIsDisabled(true);
    setCurrentDate(new Date());
  };

  const renderDate = (dateNotification: string, isEmea?: string): string => {
    const dateFormat = dateNotification.replaceAll('/', '-');
    const dateTime = DateTime.fromISO(dateFormat).toFormat(
      'cccc - dd LLLL yyyy',
      {
        locale: i18n.language,
      }
    );

    return isEmea === '1' ? `${t('end_date_recall')}: ${dateTime}` : dateTime;
  };

  const gotoProcess = (typeProcess: Process, currentDate: string): void => {
    switch (typeProcess) {
      case NOTIFICATION.REPRICING:
        dispatch(setCurrentDateSlice(currentDate));
        return history.push(
          makeUrl<UrlNotificationRepricing>(AppRoutes.SINGLE_REPRICING, {
            date: currentDate?.replaceAll('/', ''),
          })
        );

      case NOTIFICATION.RECALL:
        return history.push(AppRoutes.RECALL);

      case NOTIFICATION.INVENTORY:
        return history.push(AppRoutes.INVENTORY);

      default:
        return;
    }
  };

  const isWithinWeek = (dateNotification: string): number => {
    const dateFormat = dateNotification?.replaceAll('/', '-');
    const start = DateTime.now();
    const end = DateTime.fromISO(dateFormat);

    /**
     * Default now date begins at negative number, using plus one to compensate
     */
    const days = end.diff(start, 'days').toObject().days! + 1;

    /**
     * Including the 7th day requires 8 (not included) as top limit
     */
    return days;
  };

  const currentUser = useSelector(state => state.user);

  return (
    <>
      <UIBox width="100%" flexDirection="column" padding={3}>
        <StyledWrapper>
          <UIBox alignItems="center">
            <StyledTextButton
              label={t('seeToady')}
              onClick={seeTodayClickHandler}
            />
            <StyledTextButton
              label={t('seeCurrentMonth')}
              onClick={seeCurMonthClickHandler}
              disabled={seeCurrentMonthIsDisabled}
            />
          </UIBox>
          <UIBox alignItems="center">
            <Typography size="sm" color="grey">
              {t('profiling.filterByProcess')}
            </Typography>
            {Object.values(NOTIFICATION).map(
              process =>
                [
                  NOTIFICATION.REPRICING,
                  NOTIFICATION.RECALL,
                  NOTIFICATION.INVENTORY,
                ].includes(process) && (
                  <UIBox marginLeft={2} key={process} alignItems="center">
                    <UICheckbox
                      checked={processTypes.includes(process)}
                      disabled={filterIsDisabled}
                      onClick={(): void => processChangeHandler(process)}
                    />
                    <Typography>{t(process)}</Typography>
                  </UIBox>
                )
            )}
          </UIBox>
        </StyledWrapper>
        <StyledWrapper marginTop={8}>
          <StyledContainer width="50%">
            <UICalendar
              events={events}
              filters={processTypes}
              currentDate={currentDate}
              dayIsSelected={filterIsDisabled}
              onDayClick={calendarDayClickHandler}
              onDateChange={calendarChangeDayHandler}
              onMonthChange={calendarChangeMonthHandler}
            />
          </StyledContainer>
          {filteredEvents.length > 0 && (
            <StyledContainer
              width="50%"
              paddingTop="20px"
              flexDirection="column"
            >
              <Typography font="heavy" size="lg">
                {t('scheduledTasks')}:
              </Typography>
              {loadingEvents ? (
                <StyledUILoaderWrapper>
                  <UILoader />
                </StyledUILoaderWrapper>
              ) : (
                <>
                  {!hasEventsForToday && (
                    <>
                      <Typography as="p" color="grey" margin={'12px 0 32px'}>
                        {t('noEventsForToday')}
                      </Typography>
                      <Typography font="heavy" size="lg">
                        {t('scheduledMonthTasks')}:
                      </Typography>
                    </>
                  )}
                  {filteredEvents.map(
                    (
                      {
                        dateNotification,
                        isEmea,
                        startDate,
                        endDate,
                        typeProcess,
                        permissionProcess,
                        descriptionProcess,
                      },
                      index
                    ) => {
                      const passedDays = isWithinWeek(
                        isEmea === '0'
                          ? endDate!
                          : startDate! || dateNotification
                      );

                      return (
                        <UIBox
                          key={`${dateNotification}-${typeProcess}-${index}`}
                          flexDirection="column"
                          marginTop={4}
                        >
                          <StyledDate color="primary">
                            {renderDate(dateNotification, isEmea)}
                          </StyledDate>
                          <StyledNotiticationWrapper>
                            <UIBox>
                              <StyledDecorator />
                              <StyledNotiticationInfo flexDirection="column">
                                <Typography font="heavy">
                                  {t(typeProcess)}
                                </Typography>
                                <Typography>{descriptionProcess}</Typography>
                              </StyledNotiticationInfo>
                            </UIBox>
                            {passedDays >= 0 && (
                              <StyledNotiticationButton
                                outlined
                                disabled={
                                  (passedDays > 14 &&
                                    typeProcess !== NOTIFICATION.RECALL) ||
                                  !userHasAllPermission(
                                    currentUser,
                                    permissionProcess
                                  )
                                }
                                onClick={(): void =>
                                  gotoProcess(typeProcess, dateNotification)
                                }
                                label={
                                  typeProcess === NOTIFICATION.INVENTORYP
                                    ? t('learnMore')
                                    : t('proceed')
                                }
                              />
                            )}
                          </StyledNotiticationWrapper>
                        </UIBox>
                      );
                    }
                  )}
                </>
              )}
            </StyledContainer>
          )}
        </StyledWrapper>
      </UIBox>
      <ErrorSnackbar
        open={snackbarIsVisible}
        setIsOpen={setSnackbarVisibility}
        errorMessage={'An error occured while loading the events'}
      />
    </>
  );
};

export default PageNotification;
