export type tAvailableNationality = 'IT' | 'US' | 'Others';
export const AVAILABLE_NATIONALITIES = {
  IT: 'country.IT',
  US: 'country.US',
  Others: 'country.Others',
};

export const TIMEZONES_UTC = [
  '-11:00',
  '-10:00',
  '-09:30',
  '-09:00',
  '-08:00',
  '-07:00',
  '-06:00',
  '-05:00',
  '-04:00',
  '-03:30',
  '-03:00',
  '-02:00',
  '-01:00',
  '+00:00',
  '+01:00',
  '+02:00',
  '+03:00',
  '+03:30',
  '+04:00',
  '+04:30',
  '+05:00',
  '+05:30',
  '+05:45',
  '+06:00',
  '+06:30',
  '+07:00',
  '+08:00',
  '+08:45',
  '+09:00',
  '+09:30',
  '+10:00',
  '+10:30',
  '+11:00',
  '+12:00',
  '+12:45',
  '+13:00',
  '+14:00',
];

export const CYCLE_COUNT_STATUS_CONFIRMED = 'CONFIRMED';
export const CYCLE_COUNT_STATUS_PROCESSING = 'PROCESSING';

export const DATE_FORMATS = {
  DATEPICKER: 'yyyy-MM-dd',
};

export const PRINT_NEW_TAG_CALENDAR_DAYS_AFTER = 31;

export const PRINT_NEW_TAG_DAYS_BEFORE = 60;

export const REPRICE_DAYS_TO_SUBTRACT = 2;
export const REPRICE_DAYS_TO_SUM = 13;
