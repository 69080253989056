import { FC, useEffect, useState } from 'react';
import type { ProcessStatus } from '@/api/process';
import { ModalDataSavedError } from '@/components/layout/ModalDataSaved';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { AppRoutes } from '@/app/routers';
import { UIBox } from '../Box';
import { UIButton } from '../Button';
import { useSelector } from '@/hooks/useSelector';
import { useAppDispatch } from '@/app/store';
import { userGetPermissions } from '@/features/user/userSlice';

interface LockModalProps {
  process: string[];
  storeCode: string;
  path?: string | readonly string[] | undefined;
  onClose?: () => void;
}

const LockModal: FC<LockModalProps> = ({
  process,
  storeCode,
  onClose,
  path,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useAppDispatch();

  const [isOngoingProcess, setOngoingProcess] = useState<boolean>(false);
  const [modalViewed, setModalViewed] = useState<boolean>(false);
  const [processLock, setProcessLock] = useState<ProcessStatus>();

  const processStatus = useSelector(state => state.processStatus) || [];
  const { username } = useSelector(state => state.user);
  const { store } = useSelector(state => state.currentStore);

  useEffect(() => {
    process.forEach(current => {
      const lock = processStatus.find(
        ({ process, storeCode: processStoreCode }) =>
          process === current && processStoreCode === storeCode
      );
      if (lock && lock.isLocked) {
        setProcessLock(lock);
        if (path?.includes(AppRoutes.CYCLE_COUNT)) {
          setOngoingProcess(true);
        } else {
          setOngoingProcess(lock.lockedBy !== username);
        }
      }
    });
  });

  const onCloseHandler = (): void => {
    setModalViewed(true);
    if (onClose) {
      onClose();
    } else if (path !== AppRoutes.INTRO) {
      history.push(AppRoutes.INTRO);
    } else if (store?.storeCode) {
      dispatch(userGetPermissions({ storeCode: store?.storeCode }));
    }
  };

  return !modalViewed && isOngoingProcess ? (
    <ModalDataSavedError
      iconType="ERROR"
      $minWidth="705px"
      $minHeight="260px"
      title={t('payAttention')}
      message={
        processLock?.process === 'SOHA' && path?.includes(AppRoutes.CYCLE_COUNT)
          ? t('cycleCountInterruptedBySoh')
          : t('functionalitiesDisabled', {
              process: t(processLock?.process || ''),
              lockedBy: processLock?.lockedBy || '',
            })
      }
      open={isOngoingProcess}
      onClose={(): void => {
        setOngoingProcess(false);
        history.push(AppRoutes.INTRO);
      }}
    >
      <UIBox mt={4} alignItems="center" width="50%">
        <UIButton outlined label={t('quit')} onClick={onCloseHandler} />
      </UIBox>
    </ModalDataSavedError>
  ) : (
    <></>
  );
};

export default LockModal;
