import { useTranslation } from 'react-i18next';
import React, { useState, useEffect, useMemo } from 'react';
import { useAsync } from 'react-use';
import { useSelector } from '@/hooks/useSelector';
import { useAppDispatch } from '@/app/store';
import { useHistory } from 'react-router';
import { ClientStatus, useSocketContext } from '@/context/socketContext';

import styled from 'styled-components';
import { List } from '@material-ui/core';
import { UIBox } from '@/components/ui/Box';
import { Typography } from '@/components/ui/Typography';
import { ErrorSnackbar } from '@/components/ui/ErrorSnackbar';
import { PageLoader } from '@/components/ui/PageLoader';
import { ModalAttention } from '@/components/layout/ModalAttention';
import { InventoryItem } from '@/components/layout/InventoryItem';
import { ModalScanDetailsV2 } from '@/components/layout/ModalScanDetailsV2';

import { AppRoutes } from '@/app/routers';
import { EnumMode, InventoryStatus, InventoyStatusValues } from '@/types/enum';

import { isInventoryOwner } from '@/utils/user';
import {
  fetchInventoryList,
  initInventoryDetails,
} from '@/features/inventory/inventorySlice';
import { InventoryService } from '@/api/receive';
import { ProcessesService, ProcessStatus } from '@/api/process';
import useResetDevice from '@/hooks/useResetDevice';
import { initMQTTTags } from '@/features/devices/devicesSlice';
import { ModalDataSavedError } from '@/components/layout/ModalDataSaved';

export const StyledTitle = styled(UIBox)`
  align-items: center;
  justify-content: center;
`;

export const StyledInventoryList = styled(List)`
  flex-direction: column;
  margin-top: 28px;
`;

type ErrorModalData = {
  title?: string;
  message?: string;
  isVisible: boolean;
};

const PageInventory: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { unsubscribeInventory, clientStatus } = useSocketContext();
  const [hideError, setHideError] = useState<boolean>(false);
  const [scanModalIsVisible, setScanModalVisibility] = useState<boolean>(false);
  const [posModalIsVisible, setPosModalVisible] = useState<boolean>(false);
  const [isGettingTags, setGettingTags] = useState<boolean>(false);
  const [scanCompleted, setScanCompleted] = useState<boolean>(false);
  const [joinInventoryError, setJoinInventoryError] = useState<boolean>(false);
  const [inventoriesError, setInventoriesError] = useState<boolean>(false);
  const [startInventoryError, setStartInventoryError] =
    useState<boolean>(false);

  const [inventoryId, setInventoryId] = useState<string>();
  const [retryInventory, setRetryInventory] = useState<boolean>(false);
  const [inventoryStatus, setInventoryStatus] = useState<
    InventoyStatusValues | undefined
  >();
  const [attentionModalIsVisible, setAttentionModalVisibility] =
    useState<boolean>(false);
  const [hideInventoryModalIsVisible, setHideInventoryModalVisibility] =
    useState<boolean>(false);
  const [errorModalData, setErrorModalData] = useState<ErrorModalData>({
    isVisible: false,
  });

  const storeCode = useSelector(state => state.currentStore.store?.storeCode);
  const user = useSelector(state => state.user);
  const deviceInUse = useSelector(state => state.devices.deviceInUse);

  const { fetchInventoryListIsLoading, fetchInventoryListHasError } =
    useSelector(state => state.inventory);

  const { scheduled, passed } = useSelector(
    state => state.inventory.inventoryList
  );

  const ongoing = useSelector(state =>
    state.inventory.inventoryList.ongoing.filter(
      ({ statusName }) => statusName !== 'Waiting for Validation'
    )
  );

  const { resetDevice } = useResetDevice();

  const isOwner = useMemo(() => isInventoryOwner(user), [user]);

  useAsync(async () => {
    dispatch(initMQTTTags());
    await dispatch(initInventoryDetails());
    await dispatch(fetchInventoryList());

    // It runs if the user go back from Inventory Scan Page
    if (clientStatus === ClientStatus.CONNECTED && inventoryId) {
      await unsubscribeInventory(inventoryId);
    }
  }, []);

  useAsync(async () => {
    if (deviceInUse || joinInventoryError || startInventoryError) {
      await resetDevice();
      setScanCompleted(false);
      setGettingTags(false);
    }
  }, [joinInventoryError, startInventoryError]);

  useEffect(() => {
    if (fetchInventoryListHasError) {
      setInventoriesError(true);
    }
  }, [fetchInventoryListHasError]);

  const stopInventoryOperator = async (): Promise<void> => {
    const { scheduled, ongoing } = await dispatch(
      fetchInventoryList()
    ).unwrap();

    const inventory = [...scheduled, ...ongoing].find(
      inventory => inventory.inventoryId === inventoryId
    );

    if (inventory) {
      const { statusName, inventoryOwner } = inventory;

      if (
        (statusName === InventoryStatus.SCHEDULED ||
          statusName === InventoryStatus.REFUSED) &&
        !inventoryOwner
      ) {
        setErrorModalData({
          title: t('inventoryNotYetStarted'),
          message: t('waitInventoryOwnerStartProcess'),
          isVisible: true,
        });
        setScanModalVisibility(false);
      } else if (statusName === InventoryStatus.WAITING_APPLICATION) {
        setErrorModalData({
          title: t('inventoryProcessCompleted'),
          message: t('notAllowedToJoinnventory'),
          isVisible: true,
        });
        setScanModalVisibility(false);
      }
    }
  };

  const redirectInventoryOwner = (): void => {
    const waitingInventoryIndex = ongoing.findIndex(
      inventory =>
        inventory.inventoryId === inventoryId &&
        inventory.statusName === 'Waiting for Application'
    );

    if (waitingInventoryIndex > -1) {
      return history.push(`${AppRoutes.INVENTORY}-report/${inventoryId}`);
    } else {
      return history.push(`${AppRoutes.INVENTORY}/${inventoryId}`);
    }
  };

  const joinInventory = async (): Promise<void> => {
    if (inventoryId && storeCode) {
      const userStartedInventory = ongoing.find(
        ({ inventoryOwner }) => inventoryOwner === user.username
      );

      if (userStartedInventory) {
        redirectInventoryOwner();
      } else {
        try {
          await InventoryService.inventoryJoinInventory({
            requestBody: {
              inventoryId,
              storeCode,
            },
          });

          history.push(`${AppRoutes.INVENTORY}/${inventoryId}`);
        } catch {
          setJoinInventoryError(true);
          await stopInventoryOperator();
        }
      }
    }
  };

  const startInventory = async (): Promise<void> => {
    if (inventoryId && storeCode) {
      const process =
        (await ProcessesService.readProcessStatus({
          storeCode,
          process: 'CPOS',
          filterByStoreCode: true,
        })) || [];

      if (process && process.length > 0) {
        const cpos = process.find(
          ({ process }: ProcessStatus) => process === 'CPOS'
        );

        if (cpos && cpos.isLocked) {
          return setPosModalVisible(true);
        }
      }

      try {
        if (inventoryStatus === 'Scheduled') {
          await InventoryService.inventoryStartInventory({
            requestBody: {
              inventoryId,
              storeCode,
            },
          });

          return history.push(`${AppRoutes.INVENTORY}/${inventoryId}`);
        }
      } catch {
        // TODO - see error reponse and save in local state
        setStartInventoryError(true);
      }
    }
  };

  const hideInventory = async (): Promise<void> => {
    if (inventoryId) {
      try {
        await InventoryService.inventoryChangeInventoryStatus({
          requestBody: {
            idInventory: inventoryId,
            isRetry: retryInventory,
            status: 'Hidden',
            storeCode,
          },
        });

        await dispatch(fetchInventoryList()).unwrap();

        setHideInventoryModalVisibility(false);
      } catch {
        setHideError(true);
      }
    }
  };

  const scheduledInventoryClickHandler = async (
    inventoryId: string,
    inventoryStatus?: InventoyStatusValues,
    isRetry?: boolean,
    inventoryOwner?: string
  ): Promise<void> => {
    setInventoryId(inventoryId);
    setInventoryStatus(inventoryStatus);

    if (inventoryStatus === InventoryStatus.REFUSED) {
      if (user.username === inventoryOwner) {
        await InventoryService.inventoryChangeInventoryStatus({
          requestBody: {
            storeCode,
            idInventory: inventoryId,
            isRetry: true,
            status: 'Ongoing',
          },
        });

        return history.push(`${AppRoutes.INVENTORY}-retry/${inventoryId}`);
      } else {
        return setErrorModalData({
          title: t('inventoryNotYetStarted'),
          message: t('waitInventoryOwnerStartProcess'),
          isVisible: true,
        });
      }
    }

    if (inventoryStatus === InventoryStatus.WAITING_VALIDATION) {
      return setErrorModalData({
        title: t('inventoryProcessCompleted'),
        message: t('notAllowedToJoinnventory'),
        isVisible: true,
      });
    }

    if (isRetry && inventoryStatus !== InventoryStatus.WAITING_APPLICATION) {
      return history.push(`${AppRoutes.INVENTORY}-retry/${inventoryId}`);
    }

    if (isOwner) {
      setAttentionModalVisibility(true);
    } else {
      setScanModalVisibility(true);
    }
  };

  const hideClickHandler = (inventoryId: string, isRetry: boolean): void => {
    setInventoryId(inventoryId);
    setRetryInventory(isRetry);
    setHideInventoryModalVisibility(true);
  };

  const onGoingInventoryClickHandler = (
    inventoryId: string,
    isRetry: boolean
  ): void => {
    setInventoryId(inventoryId);
    setInventoryStatus('Ongoing');

    if (isRetry) {
      return history.push(`${AppRoutes.INVENTORY}-retry/${inventoryId}`);
    }

    return setScanModalVisibility(true);
  };

  const confirmModalAttentionHandler = async (): Promise<void> => {
    setScanModalVisibility(true);
    setAttentionModalVisibility(false);
  };

  const closeModalAttentionHandler = (): void => {
    setInventoryId(undefined);
    setInventoryStatus(undefined);
    setRetryInventory(false);
    setAttentionModalVisibility(false);
  };

  const closeModalJoinError = (): void => {
    setInventoryId(undefined);
    setInventoryStatus(undefined);
    setRetryInventory(false);
    setErrorModalData({
      title: undefined,
      message: undefined,
      isVisible: false,
    });
  };

  const closeModalHideHandler = (): void => {
    setInventoryId(undefined);
    setRetryInventory(false);
    setHideInventoryModalVisibility(false);
  };

  const closeModalScanHandler = (): void => {
    setInventoryId(undefined);
    setInventoryStatus(undefined);
    setRetryInventory(false);
    setScanModalVisibility(false);
    setScanCompleted(false);
  };

  if (fetchInventoryListIsLoading) {
    return <PageLoader />;
  }

  return (
    <>
      <ModalAttention
        open={attentionModalIsVisible}
        onConfirmClick={confirmModalAttentionHandler}
        onClose={closeModalAttentionHandler}
        messageMaxWidth="75%"
        message={t('activitiesClosed')}
      />
      <ModalAttention
        open={hideInventoryModalIsVisible}
        onConfirmClick={hideInventory}
        onClose={closeModalHideHandler}
        messageMaxWidth="75%"
        message={t('hideInventoryMessage')}
      />
      <ModalDataSavedError
        iconType="ERROR"
        $minWidth="400px"
        $minHeight="160px"
        title={t('payAttention')}
        message={t('posIsRecordingMessage')}
        open={posModalIsVisible}
        onClose={(): void => setPosModalVisible(false)}
      />
      <ModalDataSavedError
        $minWidth="400px"
        $minHeight="160px"
        title={errorModalData.title || ''}
        message={errorModalData.message || ''}
        open={errorModalData.isVisible}
        onClose={closeModalJoinError}
      />
      <ModalScanDetailsV2
        open={scanModalIsVisible}
        startGetTags={isGettingTags}
        startGettingTags={setGettingTags}
        isScanningAgain={scanCompleted}
        setIsScanCompleted={setScanCompleted}
        onClose={closeModalScanHandler}
        defaultMode={EnumMode.INVENTORY}
        disabledModes={[EnumMode.FIND]}
        hideModes={[EnumMode.FIND]}
        onStartReader={
          isOwner && inventoryStatus !== 'Ongoing'
            ? startInventory
            : joinInventory
        }
      />
      <UIBox width="100%" flexDirection="column" padding={3}>
        <StyledTitle>
          <Typography size="lg" font="heavy">
            {t('allInventoriesAvailable')}
          </Typography>
        </StyledTitle>
        <UIBox width="100%" flexDirection="column">
          {ongoing.length > 0 && (
            <StyledInventoryList>
              <Typography size="lg" font="heavy">
                {t('onGoingInventories')}
              </Typography>
              {ongoing.map(inventory => (
                <InventoryItem
                  {...inventory}
                  key={inventory.inventoryId}
                  inventoryStatus={inventory.statusName as InventoyStatusValues}
                  inventoryClickHandler={(inventoryId, _, isRetry): void =>
                    onGoingInventoryClickHandler(inventoryId, isRetry || false)
                  }
                />
              ))}
            </StyledInventoryList>
          )}
          {scheduled.length > 0 && (
            <StyledInventoryList>
              <Typography size="lg" font="heavy">
                {t('scheduledInventories')}
              </Typography>
              {scheduled.map(inventory => (
                <InventoryItem
                  {...inventory}
                  key={inventory.inventoryId}
                  inventoryStatus={inventory.statusName as InventoyStatusValues}
                  inventoryClickHandler={(
                    inventoryId,
                    inventoryStatus,
                    isRetry,
                    _,
                    inventoryOwner
                  ): Promise<void> =>
                    scheduledInventoryClickHandler(
                      inventoryId,
                      inventoryStatus,
                      isRetry,
                      inventoryOwner
                    )
                  }
                  hideClick={hideClickHandler}
                />
              ))}
            </StyledInventoryList>
          )}
          {passed.length > 0 && (
            <StyledInventoryList>
              <Typography size="lg" font="heavy">
                {t('pastInventories')}
              </Typography>
              {passed.map(inventory => (
                <InventoryItem
                  {...inventory}
                  key={inventory.inventoryId}
                  inventoryStatus={inventory.statusName as InventoyStatusValues}
                />
              ))}
            </StyledInventoryList>
          )}
        </UIBox>
        <ErrorSnackbar
          open={inventoriesError}
          setIsOpen={setInventoriesError}
          errorMessage={t('error.inventory_loading')}
        />
        <ErrorSnackbar
          open={startInventoryError}
          setIsOpen={setStartInventoryError}
          errorMessage={t('error.inventory_start')}
          ClickAwayListenerProps={{
            onClickAway: (): void => setStartInventoryError(false),
          }}
        />
        <ErrorSnackbar
          open={joinInventoryError}
          setIsOpen={setJoinInventoryError}
          errorMessage={t('error.inventory_join')}
          ClickAwayListenerProps={{
            onClickAway: (): void => setJoinInventoryError(false),
          }}
        />
        <ErrorSnackbar
          open={hideError}
          setIsOpen={setHideError}
          errorMessage={t('error.hide_inventory')}
        />
      </UIBox>
    </>
  );
};

export default PageInventory;
