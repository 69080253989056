import { FC, memo } from 'react';
import styled from 'styled-components';

import { IconButton } from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import WarningIcon from '@material-ui/icons/Warning';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

import { UIBox } from '@/components/ui/Box';
import { StyledModalProps, UIModal } from '@/components/ui/Modal';
import { Typography } from '@/components/ui/Typography';

import { palette } from '@/theme';

export interface ModalDataSavedErrorProps {
  open: boolean;
  title: string;
  message: string;
  iconType?: 'WARNING' | 'ERROR';
  onClose: () => void;
}

//#region - Styled Components

const StyledWrapper = styled(UIBox)`
  width: 100%;
  padding: 24px;
  align-items: center;
  justify-content: space-between;
  position: relative;
`;

const StyledCloseIcon = styled(IconButton)`
  position: absolute;
  right: 5px;
  top: 5px;

  svg {
    fill: ${palette.colors.primary};
  }
`;

const StyledConfirmModalContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const StyledConfirmModalTextWrapper = styled(UIBox)`
  flex-direction: column;

  h1 {
    margin: 0 0 8px;
  }

  span {
    line-height: 21px !important;
  }
`;

const StyledErrorOutlineIcon = styled(ErrorOutlineIcon)`
  margin-right: 16px;
  height: 36px;
  width: 36px;
`;

const StyledWarningIcon = styled(WarningIcon)`
  margin-right: 16px;
  height: 36px;
  width: 36px;
`;

//#endregion

export const ModalDataSavedError: FC<
  ModalDataSavedErrorProps & Partial<StyledModalProps>
> = memo(
  ({
    onClose,
    open,
    title,
    message,
    iconType,
    children,
    ...styledProps
  }): JSX.Element => {
    return (
      <UIModal
        open={open}
        $width="500px"
        $height="160px"
        onClose={onClose}
        className="modal"
        {...styledProps}
      >
        <StyledWrapper className="wrapper">
          <StyledConfirmModalContent className="content">
            <UIBox alignItems="center">
              {iconType === 'ERROR' && (
                <StyledErrorOutlineIcon htmlColor={palette.colors.yellow} />
              )}
              {iconType === 'WARNING' && (
                <StyledWarningIcon htmlColor={palette.colors.red} />
              )}
              <StyledConfirmModalTextWrapper>
                <Typography size="lg" font="heavy" as="h1">
                  {title}
                </Typography>
                <Typography>{message}</Typography>
              </StyledConfirmModalTextWrapper>
            </UIBox>
            {children}
          </StyledConfirmModalContent>
          <StyledCloseIcon onClick={onClose}>
            <HighlightOffIcon />
          </StyledCloseIcon>
        </StyledWrapper>
      </UIModal>
    );
  }
);

ModalDataSavedError.displayName = 'ModalDataSavedError';

export default ModalDataSavedError;
