import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import ReactDOM from 'react-dom';
import { App } from './app/App';
import { BFFOpenAPI } from '@/api';
import { ReceiveOpenAPI } from '@/api';
import { RepriceOpenAPI } from '@/api';
import { ProcessOpenAPI } from '@/api';
import reportWebVitals from './reportWebVitals';

import './i18n';
import store from './app/store';

BFFOpenAPI.BASE =
  process.env.REACT_APP_SRO_API_URL?.replace(/\/$/, '') || BFFOpenAPI.BASE;

ReceiveOpenAPI.BASE =
  process.env.REACT_APP_BASE_API_URL?.replace(/\/$/, '') || ReceiveOpenAPI.BASE;

RepriceOpenAPI.BASE =
  process.env.REACT_APP_REPRICE_API_URL?.replace(/\/$/, '') ||
  RepriceOpenAPI.BASE;

ProcessOpenAPI.BASE =
  process.env.REACT_APP_PROCESS_API_URL?.replace(/\/$/, '') ||
  ProcessOpenAPI.BASE;

BFFOpenAPI.TOKEN = sessionStorage.getItem('userToken') || '';
ReceiveOpenAPI.TOKEN = sessionStorage.getItem('userToken') || '';
RepriceOpenAPI.TOKEN = sessionStorage.getItem('userToken') || '';
ProcessOpenAPI.TOKEN = sessionStorage.getItem('userToken') || '';

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={null}>
      <Provider store={store}>
        <BrowserRouter basename={'/'}>
          <App />
        </BrowserRouter>
      </Provider>
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root')
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
