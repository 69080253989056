import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useSelector } from '@/hooks/useSelector';
import { useAsync } from 'react-use';
import { useAppDispatch } from '@/app/store';
import useHandleProcess from '@/hooks/useHandleProcess';
import useExpectionsNotification from '@/hooks/useExpectionsNotification';

import { ErrorSnackbar } from '@/components/ui/ErrorSnackbar';
import { UIMacroFlowMenu } from '@/components/layout/MacroFlowMenu';
import { UIButton } from '@/components/ui/Button';
import { AppRoutes } from '@/app/routers';
import { ModalDataSavedError as Modal } from '@/components/layout/ModalDataSaved';
import { ApiError, ProcessesService } from '@/api/process';

import * as S from './style';

const PageReturnsIntro: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const handleProcess = useHandleProcess();
  const returnPos = useExpectionsNotification('RTPS');
  const processLocked = useSelector(state => state.returnPos.processLocked);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [onGoingActivities, setOnGoingActivities] =
    React.useState<boolean>(false);
  const [genericError, setGenericError] = React.useState<boolean>(false);
  const isProcessEnabled = process.env.REACT_APP_DISABLE_POS === 'false';
  const [processLockedBy, setProcessLockedBy] = React.useState<string>('');
  const storeCode = useSelector(
    state => state.currentStore.store?.storeCode || ''
  );

  useAsync(async () => {
    if (processLocked) {
      await handleProcess({ process: 'RTPS', isLocked: false });
    }
  }, [dispatch, processLocked]);

  const handleReturnPosClick = async (): Promise<void> => {
    try {
      setLoading(true);
      await handleProcess({ process: 'RTPS', isLocked: true });
      history.push(AppRoutes.CUSTOMER_RETURNS_POS);
    } catch (e) {
      const error = e as ApiError;

      setLoading(false);

      if (error.status === 403) {
        setOnGoingActivities(true);
        const exceptionProcess =
          (await ProcessesService.readProcessStatus({
            storeCode,
            process: 'RTPS',
            filterByStoreCode: true,
          })) || [];

        if (exceptionProcess.length > 0 && exceptionProcess[0].isLocked) {
          setProcessLockedBy(exceptionProcess[0].lockedBy || '');
        }
      } else {
        setGenericError(true);
      }
    }
  };

  return (
    <>
      <Modal
        iconType="ERROR"
        $minWidth="420px"
        $minHeight="230px"
        title={t('tryAgain') + '!'}
        message={t('onGoingActivitiesMessage', { user: processLockedBy })}
        open={onGoingActivities}
        onClose={(): void => {
          setOnGoingActivities(false);
          setProcessLockedBy('');
        }}
      />
      <UIMacroFlowMenu title={t('menu.title')}>
        {isProcessEnabled && (
          <S.Link to={AppRoutes.CUSTOMER_RETURNS_INTRO}>
            <UIButton
              label={t('menu.returnPos')}
              loading={loading}
              disabled={loading}
              outlined={false}
              onClick={handleReturnPosClick}
              startIcon={
                <>
                  {returnPos.exceptionsOccurred && <returnPos.ExceptionsIcon />}
                </>
              }
            />
          </S.Link>
        )}
        <S.Link to={AppRoutes.CUSTOMER_RETURNS_BACKUP}>
          <UIButton label={t('menu.returnBackup')} outlined={false} />
        </S.Link>
      </UIMacroFlowMenu>
      <ErrorSnackbar
        open={genericError}
        setIsOpen={(): void => setGenericError(false)}
        errorMessage={t('error.openSalesExceptions')}
      />
    </>
  );
};

export default PageReturnsIntro;
