import { AppRoutes } from '@/app/routers';
import { useAppDispatch } from '@/app/store';
import { CTAContainer } from '@/components/layout/CTAContainer';
import RepricingList from '@/components/layout/RepricingList/RepricingList';

import { PageLoader } from '@/components/ui/PageLoader';
import { getRepriceDates } from '@/features/repricing/repriceSlice';
import { useResetAndRestoreBreadcrumbs } from '@/hooks/useBreadcrumbs';
import { useSelector } from '@/hooks/useSelector';

import { Container } from '@material-ui/core';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import styled from 'styled-components';

//#region - Styled Components

const StyledContainer = styled(Container)`
  margin-bottom: 150px;
`;

//#endregion

const RepricingDates: FC = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { dates, dataIsLoading } = useSelector(state => state.reprice);

  useResetAndRestoreBreadcrumbs([
    {
      title: t('page.repricing'),
    },
    {
      title: t('page.repricing_list'),
    },
  ]);

  useEffect(() => {
    dispatch(getRepriceDates());
  }, [dispatch]);

  if (dataIsLoading) {
    return <PageLoader />;
  }

  return (
    <>
      <StyledContainer>
        <RepricingList data={dates} />
        <CTAContainer
          type="BACK"
          label={t('back')}
          onClick={(): void => history.push(AppRoutes.INTRO)}
        />
      </StyledContainer>
    </>
  );
};

export default RepricingDates;
