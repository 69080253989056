/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Reprice } from '../models/Reprice';
import type { RepricePrintStatusRequest } from '../models/RepricePrintStatusRequest';
import { request as __request } from '../core/request';

export class RepriceService {
  /**
   * /prints
   * Update print status and gets the products to reprice
   * @returns Reprice OK
   * @throws ApiError
   */
  public static async repricePrintStatus({
    requestBody,
  }: {
    requestBody: RepricePrintStatusRequest;
  }): Promise<Reprice> {
    const result = await __request({
      method: 'PUT',
      path: `/reprice/prints`,
      body: requestBody,
      errors: {
        400: `Mandatory field missing`,
        500: `Internal Server Error`,
      },
    });
    return result.body;
  }

  /**
   * /products
   * Gets the products to reprice
   * @returns Reprice OK
   * @throws ApiError
   */
  public static async getRepriceProducts({
    storeCode,
    date,
  }: {
    storeCode: string;
    date: string;
  }): Promise<Reprice> {
    const result = await __request({
      method: 'GET',
      path: `/reprice/products`,
      query: {
        storeCode: storeCode,
        date: date,
      },
      errors: {
        400: `Mandatory field missing`,
        500: `Internal Server Error`,
      },
    });
    return result.body;
  }

  /**
   * /dates
   * Retrieve the dates for which there is a reprice following the inequality:
   * [now - daysToSubtract] <= now <= [now + daysToSum]
   * @returns any OK
   * @throws ApiError
   */
  public static async getRepriceDates({
    storeCode,
    daysToSubtract,
    daysToSum,
    orderBy,
  }: {
    storeCode: string;
    daysToSubtract: number;
    daysToSum: number;
    orderBy: 'ASC' | 'DESC';
  }): Promise<any> {
    const result = await __request({
      method: 'GET',
      path: `/reprice/dates`,
      query: {
        storeCode: storeCode,
        daysToSubtract: daysToSubtract,
        daysToSum: daysToSum,
        orderBy: orderBy,
      },
      errors: {
        400: `Mandatory field missing`,
        500: `Internal Server Error`,
      },
    });
    return result.body;
  }
}
