import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { useAsync } from 'react-use';
import { useAppDispatch } from '@/app/store';
import { useSelector } from '@/hooks/useSelector';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';

import { UIBox } from '@/components/ui/Box';
import { UICheckbox } from '@/components/ui/Checkbox';
import { ErrorSnackbar } from '@/components/ui/ErrorSnackbar';
import { PageLoader } from '@/components/ui/PageLoader';
import { ReactComponent as DeliveryIcon } from '@/assets/icons/delivery.svg';
import { Typography } from '@/components/ui/Typography';
import DeliveryListItem from './DeliveryListItem';
import RoomIcon from '@material-ui/icons/Room';

import {
  changeEpcStatus,
  checkEpcs,
  fetchDeliveryDetails,
  initCheckedEpcs,
  setManagedDeliveryInUse,
  setShouldFetchDeliveries,
} from '@/features/deliveriesManage/deliveriesManageSlice';

import { palette } from '@/theme';
import type { EpcStatus, Params, SelectValues } from './types';
import type { CloseDeliveryRequest, DeliveryDetailsRow } from '@/api/receive';
import { ProcessesService } from '@/api/process';
import type { ProcessStatus } from '@/api/process';

import {
  StyledSelect,
  StyledStickyHeader,
  StyledTablesHeader,
  StyledTableWrapper,
} from './style';
import { CTAContainer } from '@/components/layout/CTAContainer';
import { AppRoutes } from '@/app/routers';
import { ReceiveShipmentsService } from '@/api';
import { ModalAttention } from '@/components/layout/ModalAttention';
import { EpcStatusValues, EPC_STATUS } from '@/types/enum';
import { ModalDataSavedError } from '@/components/layout/ModalDataSaved';
import { UIButton } from '@/components/ui/Button';

const PageManageDeliveryDetails: React.FC = () => {
  const { t, i18n } = useTranslation();
  const params = useParams<Params>();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const deliveryDetails = useSelector(
    state => state.manageDeliveries.deliveryDetails
  );

  const { checkedEpcs } = useSelector(state => state.manageDeliveries);

  const { fetchDeliveryDetailsIsLoading, fetchDeliveryDetailsHasError } =
    useSelector(state => state.manageDeliveries);

  const [deliveries, setDeliveries] = useState<DeliveryDetailsRow[]>([]);
  const [epcStatus, setEpcStatus] = useState<EpcStatusValues | ''>('');
  const [filterTransit, setFilterTransit] = useState<boolean>(false);
  const [fetchDetailsError, setFetchDetailsError] = useState<boolean>(false);
  const [confirmError, setConfirmError] = useState<boolean>(false);
  const [changeDeliveryStatusError, setChangeDeliveryStatusError] =
    useState<boolean>(false);
  const [confirmModalIsVisible, setConfirmModalVisibility] =
    useState<boolean>(false);
  const [stolenModalIsVisible, setStolenModalVisibility] =
    useState<boolean>(false);

  useAsync(async () => {
    dispatch(initCheckedEpcs());

    await dispatch(
      fetchDeliveryDetails({
        deliveryNumber: params.deliveryNumber,
        storeCode: params.storeCode,
      })
    );

    // This redux value is used to change the delivery status
    // in useInitApplication hook inside PageHome
    dispatch(setManagedDeliveryInUse(params.deliveryNumber));
  }, []);

  useEffect(() => {
    if (
      deliveryDetails &&
      deliveryDetails.epcsInTransit &&
      deliveryDetails.epcsOthers
    ) {
      if (filterTransit) {
        setDeliveries([...deliveryDetails.epcsInTransit]);
      } else {
        setDeliveries([
          ...deliveryDetails.epcsInTransit,
          ...deliveryDetails.epcsOthers,
        ]);
      }
    }
  }, [deliveryDetails, filterTransit]);

  useEffect(() => {
    if (fetchDeliveryDetailsHasError) {
      setFetchDetailsError(true);
    }
  }, [fetchDeliveryDetailsHasError]);

  const epcsInTranist: string[] = useMemo(() => {
    if (deliveryDetails?.epcsInTransit) {
      const { epcsInTransit } = deliveryDetails;
      const epcs: string[] = epcsInTransit.map(({ epcCode }) => epcCode || '');
      return epcs;
    }

    return [];
  }, [deliveryDetails]);

  const selectValues: SelectValues = useMemo(
    () => [
      { label: t('inStock'), value: EPC_STATUS.IN_STOCK },
      { label: t('missing'), value: EPC_STATUS.MISSING },
      { label: t('sold'), value: EPC_STATUS.SOLD },
      { label: t('stolen'), value: EPC_STATUS.STOLEN },
    ],
    [t]
  );

  const isDeliveryStolen: boolean = useMemo(() => {
    if (deliveryDetails && deliveryDetails.epcsInTransit) {
      if (deliveryDetails.epcsInTransit.length > 0) {
        return deliveryDetails.epcsInTransit.every(
          ({ lastEpcStatus }) => lastEpcStatus === 'Stolen'
        );
      }

      return false;
    }

    return false;
  }, [deliveryDetails]);

  const selectChangeHandler = (
    e: React.ChangeEvent<{ value: unknown }>
  ): void => {
    const status = e.target.value as EpcStatusValues;
    dispatch(changeEpcStatus({ epcs: checkedEpcs, status }));
    setEpcStatus(status);
    dispatch(checkEpcs([]));
  };

  const epcsInTranistLength = epcsInTranist.length;

  const checkboxClickHandler = (): void => {
    if (epcsInTranist.length === checkedEpcs.length) {
      dispatch(checkEpcs([]));
    } else {
      dispatch(checkEpcs(epcsInTranist));
    }
  };

  const dateHandler = (date: string): string => {
    return DateTime.fromFormat(date, 'yyyyMMdd').toFormat('yyyy LLLL dd', {
      locale: i18n.language,
    });
  };

  const confirmHandler = async (): Promise<void> => {
    if (deliveryDetails && deliveryDetails.epcsInTransit) {
      try {
        const { epcsInTransit } = deliveryDetails;

        const requestBody: CloseDeliveryRequest = {
          deliveryNumber: params.deliveryNumber,
          isDeliveryStolen,
          epcs: epcsInTransit.map(({ epcCode, lastEpcStatus }) => ({
            epcCode,
            epcStatuses: lastEpcStatus as EpcStatus,
          })),
        };

        await ReceiveShipmentsService.receiveshipCloseDeliveryEncoded({
          requestBody: {
            encodedRequest: btoa(JSON.stringify(requestBody)),
          },
        });

        if (stolenModalIsVisible) {
          setStolenModalVisibility(false);
        } else {
          setConfirmModalVisibility(false);
        }

        dispatch(setManagedDeliveryInUse(undefined));
        dispatch(setShouldFetchDeliveries(true));

        history.push(AppRoutes.MANAGE_DELIVERY);
      } catch {
        setConfirmError(true);
      }
    }
  };

  const backHandler = async (): Promise<void> => {
    try {
      await ReceiveShipmentsService.receiveshipChangeDeliveryStatus({
        requestBody: {
          deliveryNumbers: [params.deliveryNumber],
          status: 'New',
        },
      });

      dispatch(setShouldFetchDeliveries(true));
      dispatch(setManagedDeliveryInUse(undefined));

      history.push(AppRoutes.MANAGE_DELIVERY);
    } catch {
      setChangeDeliveryStatusError(true);
    }
  };

  const disableConfirm = deliveryDetails?.epcsInTransit?.some(
    ({ lastEpcStatus }) => lastEpcStatus === EPC_STATUS.IN_TRANSIT
  );

  const [onGoingModalIsVisible, setOnGoingModalVisibility] =
    useState<boolean>(false);
  const [processLock, setProcessLock] = useState<ProcessStatus>();

  const checkProcessOnGoing = async (storeCode: string): Promise<boolean> => {
    const bisInProgress =
      (await ProcessesService.readProcessStatus({
        storeCode: storeCode,
        filterByStoreCode: true,
        process: 'BIS',
      })) || [];

    if (
      Array.isArray(bisInProgress) &&
      bisInProgress.length > 0 &&
      bisInProgress[0].isLocked
    ) {
      setProcessLock(bisInProgress[0]);
      setOnGoingModalVisibility(true);
      return true;
    }
    return false;
  };

  const handleConfirm = async (): Promise<void> => {
    const lockedProcess = await checkProcessOnGoing(params.storeCode);
    if (!lockedProcess) {
      isDeliveryStolen
        ? setStolenModalVisibility(true)
        : setConfirmModalVisibility(true);
    }
  };

  if (fetchDeliveryDetailsIsLoading) {
    return <PageLoader />;
  }

  return (
    <>
      <ModalAttention
        open={stolenModalIsVisible}
        onConfirmClick={confirmHandler}
        onClose={(): void => setStolenModalVisibility(false)}
        messageMaxWidth="75%"
        message={t('stolenDeliveryMessage')}
      />
      <ModalAttention
        open={confirmModalIsVisible}
        onConfirmClick={confirmHandler}
        onClose={(): void => setConfirmModalVisibility(false)}
        messageMaxWidth="75%"
        message={t('closeDeliveryMessage')}
      />
      <UIBox width="100%" p={3} flexDirection="column">
        <StyledStickyHeader>
          <UIBox width="100%" justifyContent="space-between">
            <UIBox
              width="40%"
              alignItems="center"
              justifyContent="space-between"
            >
              <UIBox alignItems="center">
                <DeliveryIcon />
                <UIBox ml={2} flexDirection="column">
                  <Typography font="heavy">#{params.deliveryNumber}</Typography>
                  {deliveryDetails?.arrivalDate && (
                    <Typography size="sm" color="grey" margin="8px 0 0 0">
                      {dateHandler(deliveryDetails?.arrivalDate)}
                    </Typography>
                  )}
                </UIBox>
              </UIBox>
              {deliveryDetails?.expectedQuantity && (
                <UIBox
                  bgcolor={palette.colors.text}
                  pl={1}
                  pr={1}
                  width="max-content"
                  height="16px"
                  borderRadius="100px"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Typography size="sm" color="white">
                    {t('itemsFound', {
                      itemsFound: deliveryDetails?.expectedQuantity,
                    })}
                  </Typography>
                </UIBox>
              )}
              <UIBox alignItems="center">
                <RoomIcon htmlColor={palette.colors.text} />
                <Typography font="heavy" margin="0 0 0 4px">
                  {params.storeCode}
                </Typography>
              </UIBox>
            </UIBox>
            <UIBox ml="auto" alignItems="center">
              <Typography>{t('filterByStatus')}:</Typography>
              <UIBox alignItems="center">
                <UICheckbox
                  checked={filterTransit}
                  onClick={(): void => setFilterTransit(!filterTransit)}
                />
                <Typography>{t('inTransit')}</Typography>
              </UIBox>
            </UIBox>
          </UIBox>
          <UIBox
            mt={3}
            mb={3}
            width="40%"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography>{t('applyStatusChange')}</Typography>
            <StyledSelect
              marg="0 0 0 24px"
              variant="outlined"
              onBlur={(): void => setEpcStatus('')}
              value={epcStatus}
              values={selectValues}
              onChange={selectChangeHandler}
            />
          </UIBox>
          <StyledTablesHeader>
            <UICheckbox
              onClick={checkboxClickHandler}
              checked={
                epcsInTranistLength > 0 &&
                epcsInTranistLength === checkedEpcs.length
              }
            />
            <Typography font="heavy">UPC</Typography>
            <Typography font="heavy">EPC</Typography>
            <Typography font="heavy">{t('attributes')}</Typography>
            <Typography font="heavy">{t('lastEpcStatus')}</Typography>
          </StyledTablesHeader>
        </StyledStickyHeader>
        <StyledTableWrapper>
          {deliveries.map((delivery, index) => (
            <DeliveryListItem {...delivery} key={index} />
          ))}
        </StyledTableWrapper>
        <CTAContainer
          type="TWO_BUTTONS"
          onBackClick={backHandler}
          onClick={handleConfirm}
          disabledMainAction={disableConfirm}
          mainButtonLabel={t('confirm')}
        />
        <ErrorSnackbar
          open={fetchDetailsError}
          setIsOpen={setFetchDetailsError}
          errorMessage={
            fetchDeliveryDetailsHasError?.body ||
            fetchDeliveryDetailsHasError?.body?.errorMessage
          }
          ClickAwayListenerProps={{
            onClickAway: (): void => setFetchDetailsError(false),
          }}
        />
        <ErrorSnackbar
          open={changeDeliveryStatusError}
          setIsOpen={setChangeDeliveryStatusError}
          errorMessage={t('error.changeDeliveyrStatus')}
          ClickAwayListenerProps={{
            onClickAway: (): void => setChangeDeliveryStatusError(false),
          }}
        />
        <ErrorSnackbar
          open={confirmError}
          setIsOpen={setConfirmError}
          errorMessage={t('error.confirmDeliveryEpcs')}
          ClickAwayListenerProps={{
            onClickAway: (): void => setConfirmError(false),
          }}
        />

        <ModalDataSavedError
          iconType="ERROR"
          $minWidth="705px"
          $minHeight="260px"
          title={t('payAttention')}
          message={t('manageDeliveryDisabled', {
            process: t(processLock?.process || ''),
            lockedBy: processLock?.lockedBy || '',
            store: params.storeCode || '',
          })}
          open={onGoingModalIsVisible}
          onClose={(): void => {
            setOnGoingModalVisibility(false);
          }}
        >
          <UIBox mt={4} alignItems="center" width="50%">
            <UIButton
              outlined
              label={t('quit')}
              onClick={(): void => setOnGoingModalVisibility(false)}
            />
          </UIBox>
        </ModalDataSavedError>
      </UIBox>
    </>
  );
};

export default PageManageDeliveryDetails;
