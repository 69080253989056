import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { AppRoutes } from '@/app/routers';
import { useAppDispatch } from '@/app/store';
import { UIMacroFlowMenu } from '@/components/layout/MacroFlowMenu';
import { UIButton } from '@/components/ui/Button';

import * as S from './style';
import { ApiError } from '@/api/receive';
import { ProcessesService, ProcessStatus } from '@/api/process';
import { useSelector } from '@/hooks/useSelector';
import { ModalDataSavedError as Modal } from '@/components/layout/ModalDataSaved';
import { ErrorSnackbar } from '@/components/ui/ErrorSnackbar';
import { useAsync } from 'react-use';
import useHandleProcess from '@/hooks/useHandleProcess';
import { ModalAttention } from '@/components/layout/ModalAttention';
import { setProcessLocked } from '@/features/loader/loaderSlice';
import useResetDevice from '@/hooks/useResetDevice';
import { MessageBody, useSignalRContext } from '@/context/signalR';
import { Reader } from '../PageReaderUpload';
import {
  isDeleteEPCAllowed,
  isLoaderSuperUser,
  isManualLoaderAllowed,
  isReaderUploadAllowed,
  isStoreMigrator,
} from '@/utils/user';
import { UIBox } from '@/components/ui/Box';
import { initMQTTTags } from '@/features/devices/devicesSlice';
import { updateProcessStatus } from '@/features/processStatus/processStatusSlice';

export const PageStoreMigratorIntro: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const handleProcess = useHandleProcess();
  const { resetDevice } = useResetDevice();
  const { connect, connected, retrieveStatus, disconnect } =
    useSignalRContext();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [loadingReader, setLoadingReader] = React.useState<boolean>(false);
  const [genericError, setGenericError] = React.useState<boolean>(false);
  const [loaderNotStarted, setLoaderNotStarted] =
    React.useState<boolean>(false);
  const [startReaderModalIsVisible, setStartReaderModalVisibility] =
    React.useState<boolean>(false);
  const [readerPendingModalIsVisible, setReaderPendingModalVisibility] =
    React.useState<boolean>(false);
  const [onGoingActivities, setOnGoingActivities] =
    React.useState<boolean>(false);

  const user = useSelector(state => state.user);
  const userId = useSelector(state => state.user.username || '');
  const manualProcessLocked = useSelector(
    state => state.loader.manualProcessLocked
  );
  // const deleteProcessLocked = useSelector(
  //   state => state.loader.deleteProcessLocked
  // );
  const deviceInUse = useSelector(state => state.devices?.deviceInUse);
  const storeCode = useSelector(
    state => state.currentStore.store?.storeCode || ''
  );

  const isSuperUser = isLoaderSuperUser(user);

  useAsync(async () => {
    if (connected) {
      await disconnect();
    }
  }, []);

  useAsync(async () => {
    if (manualProcessLocked) {
      dispatch(
        setProcessLocked({
          process: 'manualProcessLocked',
          locked: false,
        })
      );

      await handleProcess({ process: 'MSUP', isLocked: false });
    }

    // if (deleteProcessLocked) {
    //   dispatch(
    //     setProcessLocked({
    //       process: 'deleteProcessLocked',
    //       locked: false,
    //     })
    //   );

    //   await handleProcess({ process: 'DSUP', isLocked: false });
    // }
  }, []);

  useAsync(async () => {
    if (deviceInUse) {
      dispatch(initMQTTTags());
      await resetDevice();
    }
  }, []);

  const showError = (error: ApiError): void => {
    if (error.status === 403) {
      setOnGoingActivities(true);
    } else {
      setGenericError(true);
    }
  };

  const handleManualLoaderClick = async (): Promise<void> => {
    try {
      setLoading(true);

      await handleProcess({ process: 'MSUP', isLocked: true });

      dispatch(
        setProcessLocked({
          process: 'manualProcessLocked',
          locked: true,
        })
      );

      history.push(AppRoutes.UPLOAD_STORE_MANUAL);
    } catch (e) {
      setLoading(false);
      showError(e as ApiError);
    }
  };

  const onLinkClick = async (): Promise<void> => {
    try {
      setLoadingReader(true);

      const processes: ProcessStatus[] =
        (await ProcessesService.readProcessStatus({
          storeCode,
          filterByStoreCode: true,
        })) || [];
      await dispatch(updateProcessStatus(processes));

      if (
        processes &&
        processes.find(({ process }) => process === 'MSUP')?.isLocked
      ) {
        return setOnGoingActivities(true);
      }

      await connect('RSUP');

      const { data } = (await retrieveStatus('RSUP')) as MessageBody<Reader>;

      setLoadingReader(false);

      if (!data) {
        return isSuperUser
          ? setStartReaderModalVisibility(true)
          : setLoaderNotStarted(true);
      }

      switch (data.status) {
        case 'PENDING':
          if (userId === data.owner) {
            return history.push(
              `${AppRoutes.UPLOAD_STORE_READER_RECAP}?id=${data.id}`
            );
          } else {
            return setReaderPendingModalVisibility(true);
          }

        case 'READING':
          return history.push(AppRoutes.UPLOAD_STORE_READER);

        default:
          return;
      }
    } catch {}
  };

  const onStartReaderClick = async (): Promise<void> => {
    try {
      await handleProcess({ process: 'RSUP', isLocked: true });

      dispatch(
        setProcessLocked({
          process: 'readerProcessLocked',
          locked: true,
        })
      );

      history.push(AppRoutes.UPLOAD_STORE_READER);
    } catch {
      setGenericError(true);
    }
  };

  return (
    <>
      <Modal
        iconType="ERROR"
        $minWidth="420px"
        $minHeight="230px"
        title={t('tryAgain')}
        message={t('onGoingStoreUploadMessage')}
        open={onGoingActivities}
        onClose={(): void => setOnGoingActivities(false)}
      />
      <Modal
        $minWidth="605px"
        $minHeight="250px"
        title={t('payAttention')}
        message={t('loaderNotStarted', { storeCode })}
        open={loaderNotStarted}
        onClose={(): void => setLoaderNotStarted(false)}
      >
        <UIButton
          outlined
          label={t('ok')}
          onClick={(): void => setLoaderNotStarted(false)}
        />
      </Modal>
      <Modal
        $minWidth="605px"
        $minHeight="200px"
        title={t('payAttention')}
        message={t('loaderPendingMessage')}
        open={readerPendingModalIsVisible}
        onClose={(): void => setReaderPendingModalVisibility(false)}
      >
        <UIBox mt={4} alignItems="end" width="60%" alignSelf="flex-end">
          <UIButton
            outlined
            label={t('ok')}
            onClick={(): void => setReaderPendingModalVisibility(false)}
          />
        </UIBox>
      </Modal>
      <ModalAttention
        messageMaxWidth="80%"
        open={startReaderModalIsVisible}
        message={t('startProcessReader', { storeCode })}
        onConfirmClick={onStartReaderClick}
        onClose={(): void => setStartReaderModalVisibility(false)}
      />
      <UIMacroFlowMenu title={t('menu.title')}>
        {isManualLoaderAllowed(user) && (
          <S.Link to={AppRoutes.STORE_MIGRATOR}>
            <UIButton
              label={t('menu.manualStoreUpload')}
              loading={loading}
              disabled={loading}
              outlined={false}
              onClick={handleManualLoaderClick}
            />
          </S.Link>
        )}
        {isStoreMigrator(user) && (
          <S.Link to={AppRoutes.UPLOAD_VAS_STORE}>
            <UIButton label={t('menu.vasStoreUpload')} outlined={false} />
          </S.Link>
        )}
        {isReaderUploadAllowed(user) && (
          <S.Link to={AppRoutes.STORE_MIGRATOR}>
            <UIButton
              label={t('menu.uploadStoreReader')}
              outlined={false}
              loading={loadingReader}
              disabled={loadingReader}
              onClick={onLinkClick}
            />
          </S.Link>
        )}
        {isDeleteEPCAllowed(user) && (
          <S.Link to={AppRoutes.DELETE_EPC}>
            <UIButton label={t('menu.deleteEpc')} outlined={false} />
          </S.Link>
        )}
      </UIMacroFlowMenu>
      <ErrorSnackbar
        open={genericError}
        setIsOpen={(): void => setGenericError(false)}
        errorMessage={t('error.openProcess')}
      />
    </>
  );
};

export default PageStoreMigratorIntro;
