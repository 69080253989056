import { useAppDispatch } from '@/app/store';

import { addItem, reset } from '@/features/breadcrumbs/breadcrumbsSlice';

import { BreadcrumbsItem } from '@/types/breadcrumbs';
import { useEffect } from 'react';

export const useBreadcrumbs = (items: BreadcrumbsItem[]): void => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    items.forEach(item => dispatch(addItem(item)));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export const useResetBreadcrumbs = (): void => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(reset());
  }, [dispatch]);
};

export const useResetAndRestoreBreadcrumbs = (
  items: BreadcrumbsItem[]
): void => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const restore = async () => {
      await dispatch(reset());
      items.forEach(item => dispatch(addItem(item)));
    };

    restore();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
