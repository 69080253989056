import { ModalDataSavedError as Modal } from '@/components/layout/ModalDataSaved';
import { UIBox } from '@/components/ui/Box';
import { UIButton } from '@/components/ui/Button';
import {
  setExpiringSessionModalIsClosed,
  setShowExpiringSessionModal,
} from '@/features/expiringSession/expiringSession';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

interface SessionExpiringProps {}

const ExpiringSessionModal: FC<SessionExpiringProps> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { showExpiringSessionModal, expiringSessionModalIsClosed } =
    useSelector(state => state.expiringSession);

  const closeModal = (): void => {
    dispatch(setExpiringSessionModalIsClosed(true));
    dispatch(setShowExpiringSessionModal(false));
  };

  return showExpiringSessionModal && !expiringSessionModalIsClosed ? (
    <Modal
      iconType="ERROR"
      $minWidth="500px"
      $minHeight="250px"
      title={t('expiringSession.title')}
      message={t('expiringSession.message')}
      open={showExpiringSessionModal}
      onClose={(): void => closeModal()}
    >
      <UIBox mt={4} alignItems="center" width="30%" alignSelf="center">
        <UIButton
          outlined
          label={t('expiringSession.action')}
          onClick={(): void => closeModal()}
        />
      </UIBox>
    </Modal>
  ) : (
    <></>
  );
};

export default ExpiringSessionModal;
