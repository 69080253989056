import { ProcessStatus } from '@/api/process';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: ProcessStatus[] = [];

const processStatusSlice = createSlice({
  name: 'processStatus',
  initialState,
  reducers: {
    updateProcessStatus: (
      state,
      { payload }: PayloadAction<ProcessStatus[]>
    ) => {
      state = payload || [];
      return state;
    },
  },
});

export const { updateProcessStatus } = processStatusSlice.actions;
export default processStatusSlice.reducer;
