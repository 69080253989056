import { SessionExpiredSlice } from '@/types/sessionExpired';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: SessionExpiredSlice = {
  showSessionExpiredModal: false,
};

const sessionExpiredSlice = createSlice({
  name: 'sessionExpired',
  initialState,
  reducers: {
    setShowSessionExpiredModal: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.showSessionExpiredModal = payload;
      return state;
    },
  }
});

export const { setShowSessionExpiredModal } = sessionExpiredSlice.actions;
export default sessionExpiredSlice.reducer;
