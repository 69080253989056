import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { FormControlLabel, List, ListSubheader } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import Checkbox from '@material-ui/core/Checkbox';
import { palette } from '@/theme';
import { RepricingListItem } from '@/components/layout/RepricingListItem';
import { PaginationList } from '@/components/layout/PaginationList';
import { UIBox } from '@/components/ui/Box';
import { RepriceBrand, RepriceUpc } from '@/api/reprice';
import { Typography } from '@/components/ui/Typography';
import { useTranslation } from 'react-i18next';
import { UIAccordion } from '@/components/ui/Accordion';
import displayProductHeader from '@/utils/displayProductHeader';

interface RepriceBrandListProps {
  brand: RepriceBrand;
  totalUpcs: number;
  checkedUpcs: string[];
  brandsSelected: string[];
  selectedTab: number;
  setBrandsSelected: React.Dispatch<React.SetStateAction<string[]>>;
  setCheckedUpcs: React.Dispatch<React.SetStateAction<string[]>>;
  setUpcPrintQuantity: React.Dispatch<
    React.SetStateAction<Map<string, number>>
  >;
  onSeeItemsClick: (brandName: string, upcs: RepriceUpc[]) => void;
}

//#region - Styled Components
const StyledCheckbox = styled(Checkbox)`
  svg {
    fill: #005192;
  }
`;

const StyledList = styled(List)`
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border: 1px solid ${palette.colors.lightGrey};
  background: ${palette.colors.midGrey};
  margin-bottom: 10px;
  padding-bottom: 0px;
`;

const StyledSubHeader = styled(ListSubheader)`
  background: ${palette.colors.lightGrey};
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  padding: 0 24px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  > div {
    display: flex;
    align-items: center;
    gap: 12px;
  }
  .banner-accordion {
    height: 30px;
  }
  .MuiFormControlLabel-label.Mui-disabled {
    color: rgba(0, 0, 0, 0.54);
  }
`;

const StyledSubHeaderInfo = styled(UIBox)`
  align-items: center;

  &&& > span {
    cursor: pointer;
    color: #005192;
  }

  & > * {
    &:not(:last-child) {
      margin-right: 21px;
    }
  }
`;

const StyledWarningWrapper = styled(UIBox)`
  align-items: center;

  & > * {
    &:not(:last-child) {
      margin-right: 8px;
    }
  }
`;

const StyledWarningIcon = styled(ErrorIcon)`
  fill: #f6bb3b;
  width: 20px;
  height: 20px;
`;
//#endregion

const RepriceBrandList: React.FC<RepriceBrandListProps> = ({
  brand,
  selectedTab,
  checkedUpcs,
  totalUpcs,
  setCheckedUpcs,
  setUpcPrintQuantity,
  onSeeItemsClick,
  brandsSelected,
  setBrandsSelected,
}) => {
  const { t } = useTranslation();
  const { brandName, upcs } = brand;

  const [upcsInPage, setUpcsInPage] = useState<RepriceUpc[]>([]);
  const [open, setOpen] = useState<boolean>(false);

  const upcsInList = useMemo(
    () =>
      upcsInPage
        .filter(({ numberItems }) => numberItems !== 0)
        .map(({ upcCode }) => upcCode),
    [upcsInPage]
  );

  useEffect(() => {
    // Takes only the selectable upcs in common between upcInList & checkedUpcs
    const selectableUps = checkedUpcs.filter(upc => upcsInList.includes(upc));

    if (upcsInList.length !== 0 && selectableUps.length === upcsInList.length) {
      setBrandsSelected(prevBrands => [...prevBrands, brandName]);
    }
  }, [checkedUpcs, upcsInList]);

  useEffect(() => {
    setOpen(false);
  }, [selectedTab]);

  const handleSelectAll = (upcs: RepriceUpc[]): void => {
    const upcCodes = upcs
      .filter(({ numberItems }) => numberItems > 0)
      .map(({ upcCode }) => upcCode);

    setBrandsSelected(prevBrands => {
      if (prevBrands.includes(brandName)) {
        return prevBrands.filter(item => item !== brandName);
      } else {
        return [...prevBrands, brandName];
      }
    });

    if (brandsSelected.includes(brandName)) {
      setCheckedUpcs(prevChecked =>
        prevChecked.filter(item => !upcCodes.includes(item))
      );
    } else {
      setCheckedUpcs(prevChecked => [
        ...new Set([...prevChecked, ...upcCodes]),
      ]);
    }
  };

  const onProductCheckboxClick = (upc: string, brandName?: string): void => {
    if (checkedUpcs.includes(upc)) {
      setCheckedUpcs(prevChecked => prevChecked.filter(item => item !== upc));

      if (brandName) {
        if (brandsSelected.includes(brandName)) {
          setBrandsSelected(prevBrands =>
            prevBrands.filter(item => item !== brandName)
          );
        }
      }
    } else {
      setCheckedUpcs(prevChecked => [...prevChecked, upc]);
    }
  };

  return (
    <StyledList
      subheader={
        <StyledSubHeader>
          <div>
            <UIAccordion
              className="banner-accordion"
              expanded={open}
              onClick={(): void => setOpen(prev => !prev)}
            />
            <FormControlLabel
              control={
                <StyledCheckbox
                  disabled={!open}
                  checked={brandsSelected.includes(brandName)}
                  onChange={(): void => handleSelectAll(upcs)}
                />
              }
              label={brandName}
            />
          </div>
          <div>
            {upcs.find(
              ({ firstPrice, secondPrice }) =>
                Number(firstPrice?.price) === 0 ||
                Number(secondPrice?.price) === 0
            ) && (
              <StyledSubHeaderInfo>
                <StyledWarningWrapper>
                  <StyledWarningIcon />
                  <Typography size="xs">
                    {t('reprice.items_with_price_zero', {
                      price: 0,
                    })}
                  </Typography>
                </StyledWarningWrapper>
                <Typography
                  font="heavy"
                  onClick={(): void => onSeeItemsClick(brandName, upcs)}
                >
                  {t('see_items')}
                </Typography>
              </StyledSubHeaderInfo>
            )}
            {t('itemsFound', {
              itemsFound: upcs.reduce(
                (count, { numberItems }) => count + numberItems,
                0
              ),
            })}
          </div>
        </StyledSubHeader>
      }
    >
      {open && (
        <PaginationList
          setItemsInPage={setUpcsInPage}
          data={upcs}
          pageSize={totalUpcs}
          renderItem={(repriceItem: RepriceUpc, index: number): JSX.Element => (
            <React.Fragment
              key={`${repriceItem.upcCode}-${repriceItem.brandDescription}`}
            >
              {displayProductHeader(upcs, 'SOLD', index) && (
                <UIBox width="100%">
                  <UIBox mt={2} alignItems="center">
                    <Typography font="heavy">{t('modelCode')}:</Typography>
                    <Typography margin="0 0 0 8px">
                      {repriceItem.modelCode}
                    </Typography>
                  </UIBox>
                </UIBox>
              )}
              <RepricingListItem
                $v2={
                  repriceItem.statusPrint !== 'not printed' ||
                  repriceItem.numberItems === 0
                }
                key={`${repriceItem.upcCode}-${repriceItem.brandDescription}`}
                repriceItem={repriceItem}
                checkedUpcs={checkedUpcs}
                brandName={brandName}
                setUpcPrintQuantity={setUpcPrintQuantity}
                onProductCheckboxClick={onProductCheckboxClick}
                printStatusIsVisible={repriceItem.statusPrint !== 'not printed'}
                printCounterIsVisible={
                  repriceItem.statusPrint !== 'not printed' ||
                  repriceItem.numberItems === 0
                }
              />
            </React.Fragment>
          )}
        />
      )}
    </StyledList>
  );
};

export default RepriceBrandList;
