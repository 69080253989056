import { ExpiringSessionSlice } from '@/types/expiringSession';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: ExpiringSessionSlice = {
  showExpiringSessionModal: false,
  expiringSessionModalIsClosed: false,
};

const sessionExpiringSlice = createSlice({
  name: 'sessionExpiring',
  initialState,
  reducers: {
    setShowExpiringSessionModal: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.showExpiringSessionModal = payload;
      return state;
    },
    setExpiringSessionModalIsClosed: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.expiringSessionModalIsClosed = payload;
      return state;
    },
    initExpiringSessionState: () => initialState
  }
});

export const { setShowExpiringSessionModal, setExpiringSessionModalIsClosed, initExpiringSessionState } = sessionExpiringSlice.actions;
export default sessionExpiringSlice.reducer;
