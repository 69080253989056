/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ProcessStatus } from '../models/ProcessStatus';
import { request as __request } from '../core/request';

export class ProcessesService {
  /**
   * /updateProcessStatus
   * Update process locked flag in database by store code and process.
   * @returns ProcessStatus OK
   * @throws ApiError
   */
  public static async updateProcessStatus({
    requestBody,
  }: {
    requestBody: ProcessStatus;
  }): Promise<ProcessStatus> {
    const result = await __request({
      method: 'PUT',
      path: `/processes/updateProcessStatus`,
      body: requestBody,
      errors: {
        400: `Mandatory field missing`,
        500: `Internal Server Error`,
      },
    });
    return result.body;
  }

  /**
   * /handlingProcessStatus
   * Handle lock and unlock for single store. if you try to lock an already blocked process for a store it will return 403
   * @returns any OK
   * @throws ApiError
   */
  public static async handlingProcessStatus({
    requestBody,
  }: {
    requestBody: ProcessStatus;
  }): Promise<any> {
    const result = await __request({
      method: 'PUT',
      path: `/processes/handlingProcessStatus`,
      body: requestBody,
      errors: {
        400: `Mandatory field missing`,
        403: `Forbidden`,
        500: `Internal Server Error`,
      },
    });
    return result.body;
  }

  /**
   * /createProcessStatus
   * Insert process,storeCode and locked flag in database.
   * @returns ProcessStatus OK
   * @throws ApiError
   */
  public static async createProcessStatus({
    requestBody,
  }: {
    requestBody: ProcessStatus;
  }): Promise<ProcessStatus> {
    const result = await __request({
      method: 'POST',
      path: `/processes/createProcessStatus`,
      body: requestBody,
      errors: {
        400: `Mandatory field missing`,
        500: `Internal Server Error`,
      },
    });
    return result.body;
  }

  /**
   * /readProcessStatus
   * Get process status filtered by storeCode or process or both or full.
   * @returns ProcessStatus OK
   * @throws ApiError
   */
  public static async readProcessStatus({
    filterByStoreCode,
    storeCode,
    process,
  }: {
    filterByStoreCode: boolean;
    storeCode?: string;
    process?: string;
  }): Promise<Array<ProcessStatus>> {
    const result = await __request({
      method: 'GET',
      path: `/processes/readProcessStatus`,
      query: {
        filterByStoreCode: filterByStoreCode,
        storeCode: storeCode,
        process: process,
      },
      errors: {
        400: `Mandatory field missing`,
        500: `Internal Server Error`,
      },
    });
    return result.body;
  }

  /**
   * /deleteProcessStatus
   * Make a delete into PROCESS_STATUS by store code and process name.
   * @returns any OK
   * @throws ApiError
   */
  public static async deleteProcessStatus({
    storeCode,
    process,
  }: {
    storeCode: string;
    process: string;
  }): Promise<any> {
    const result = await __request({
      method: 'DELETE',
      path: `/processes/deleteProcessStatus`,
      query: {
        storeCode: storeCode,
        process: process,
      },
      errors: {
        400: `Mandatory field missing`,
        500: `Internal Server Error`,
      },
    });
    return result.body;
  }
}
