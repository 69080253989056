import React, { useEffect } from 'react';
import { useAsync } from 'react-use';
import { useAppDispatch } from '@/app/store';
import { useSelector } from '@/hooks/useSelector';
import {
  getSaleExceptionsItems,
  removeManagedPosItems,
  removeNotManagedPosItems,
  resetManagedCheckedItems,
  resetNotManagedCheckedItems,
  setDataSaved,
  setLiteFunctionality,
  setProcessLocked,
} from '@/features/pos/posSlice';

import ProductList from '../components/ProductList';
import { PageLoader } from '@/components/ui/PageLoader';
import { ListType } from '../components/ProductList/types';
import { CTAContainer } from '@/components/layout/CTAContainer';
import { useHistory } from 'react-router';
import { AppRoutes } from '@/app/routers';
import useResetDevice from '@/hooks/useResetDevice';
import useConfirmSoldItems from '../hooks/useConfirmSoldItems';
import { ErrorSnackbar } from '@/components/ui/ErrorSnackbar';
import { ModalAttention } from '@/components/layout/ModalAttention';
import { useTranslation } from 'react-i18next';
import { Typography } from '@/components/ui/Typography';
import { UIBox } from '@/components/ui/Box';
import { UIButtonWithIcon } from '@/components/ui/Button';
import RefreshIcon from '@material-ui/icons/Refresh';
import { ModalDataSavedError } from '@/components/layout/ModalDataSaved';
import { AlertSnackbar } from '@/components/ui/AlertSnackbar';
import { TtlMessage } from '@/components/SessionExpiring/ttlMessage';

const PageSaleExceptions: React.FC = () => {
  const { t } = useTranslation();
  const dataSavedSnackbarDuration = 4000;
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { resetDevice } = useResetDevice();
  const confirmSoldItems = useConfirmSoldItems();
  const [getExceptionsError, setGetExceptionsError] =
    React.useState<boolean>(false);
  const [removeItemError, setRemoveItemError] = React.useState<boolean>(false);
  const [confirmModalIsVisible, setConfirmModalVisibility] =
    React.useState<boolean>(false);

  const [confirmDeleteExceptions, setConfirmDeleteExceptions] = React.useState<
    string | undefined
  >();
  const [loadingDeleteExceptions, setLoadingDeleteExceptions] =
    React.useState<boolean>(false);

  const {
    dataSaved,
    exceptionsManaged,
    exceptionsNotManaged,
    removePosItemsHasError,
    getSaleExceptionsHasError,
    getSaleExceptionsIsLoading,
    checkedManagedItems,
    checkedNotManagedItems,
  } = useSelector(state => state.pos);

  const deviceInUse = useSelector(state => state.devices.deviceInUse);

  const selectedUPC = React.useMemo(
    () => ({
      managed: Object.keys(checkedManagedItems),
      notManaged: Object.keys(checkedNotManagedItems),
    }),
    [checkedManagedItems, checkedNotManagedItems]
  );

  const exceptionsUPC = React.useMemo(
    () => ({
      managed: exceptionsManaged.map(({ upcCode }) => upcCode || ''),
      notManaged: exceptionsNotManaged.map(({ upcCode }) => upcCode || ''),
    }),
    [exceptionsManaged, exceptionsNotManaged]
  );

  useAsync(async () => {
    await dispatch(getSaleExceptionsItems([]));
  }, []);

  useEffect(() => {
    dispatch(setLiteFunctionality(false));
    dispatch(setProcessLocked(true));
  }, [dispatch]);

  useAsync(async () => {
    if (deviceInUse) {
      await resetDevice();
    }
  }, []);

  useEffect(() => {
    if (getSaleExceptionsHasError) {
      setGetExceptionsError(true);
    }
  }, [getSaleExceptionsHasError]);

  useEffect(() => {
    if (dataSaved) {
      setTimeout(() => {
        dispatch(setDataSaved(false));
      }, dataSavedSnackbarDuration);
    }
  }, [dataSaved, dispatch]);

  const handleConfirmSoldItems = async (): Promise<void> => {
    try {
      await confirmSoldItems.execute();
      await dispatch(getSaleExceptionsItems([]));
      setConfirmModalVisibility(false);
    } catch {}
  };

  const handleCloseConfirmModal = async (): Promise<void> => {
    try {
      setConfirmModalVisibility(false);
      dispatch(resetManagedCheckedItems());
      dispatch(resetNotManagedCheckedItems());
      await dispatch(getSaleExceptionsItems([])).unwrap();
    } catch {}
  };

  const handleRefreshClick = async (): Promise<void> => {
    await dispatch(getSaleExceptionsItems([]));
  };

  const handleRemoveNotManagedItemClick = async (): Promise<void> => {
    try {
      await dispatch(
        removeNotManagedPosItems(
          selectedUPC.notManaged.length === exceptionsUPC.notManaged.length
        )
      ).unwrap();
      await dispatch(getSaleExceptionsItems([])).unwrap();
      dispatch(resetNotManagedCheckedItems());
    } catch {
      setRemoveItemError(true);
    }
  };

  const handleRemoveManagedItemClick = async (): Promise<void> => {
    try {
      await dispatch(
        removeManagedPosItems(
          selectedUPC.managed.length === exceptionsUPC.managed.length
        )
      ).unwrap();
      await dispatch(getSaleExceptionsItems([])).unwrap();
      dispatch(resetManagedCheckedItems());
    } catch {
      setRemoveItemError(true);
    }
  };

  const handleDataSavedErrorModalClose = async (): Promise<void> => {
    confirmSoldItems.setError(false);
  };

  const handleConfirmDelete = async (): Promise<void> => {
    setLoadingDeleteExceptions(true);
    switch (confirmDeleteExceptions) {
      case 'notManaged':
        await handleRemoveNotManagedItemClick();
        break;
      case 'managed':
        await handleRemoveManagedItemClick();
        break;
    }
    setConfirmDeleteExceptions(undefined);
    setLoadingDeleteExceptions(false);
  };

  return (
    <>
      {getSaleExceptionsIsLoading ? (
        <PageLoader />
      ) : (
        <>
          <ModalAttention
            open={confirmDeleteExceptions !== undefined}
            onConfirmClick={handleConfirmDelete}
            onClose={(): void => setConfirmDeleteExceptions(undefined)}
            messageMaxWidth="95%"
            modalMaxWidth="50%"
            message={t('confirmDeleteExceptions')}
            loadingConfirm={loadingDeleteExceptions}
            disableConfirm={loadingDeleteExceptions}
          />
          <ModalDataSavedError
            $minWidth="550px"
            $minHeight="250px"
            iconType="WARNING"
            open={confirmSoldItems.error && confirmSoldItems.errorCode === 400}
            onClose={handleDataSavedErrorModalClose}
            title={t('payAttention')}
            message={t('error.confirmAlreadySoldItem')}
          />
          <ModalAttention
            messageMaxWidth="300px"
            open={confirmModalIsVisible}
            disableConfirm={confirmSoldItems.loading}
            loadingConfirm={confirmSoldItems.loading}
            onConfirmClick={handleConfirmSoldItems}
            onClose={handleCloseConfirmModal}
            message={t('confirmSoldItemsMesssage')}
          />
          <TtlMessage process={'SLEX'} />
          {exceptionsNotManaged.length === 0 &&
          exceptionsManaged.length === 0 ? (
            <>
              <UIBox p={2}
                     ml="auto"
                     justifyContent={"right"}
              >
                <UIButtonWithIcon
                  margin="0 0 0 auto"
                  label={t('refresh')}
                  startIcon={<RefreshIcon />}
                  onClick={handleRefreshClick}
                />
              </UIBox>
              <UIBox mt={20} alignItems="center" justifyContent="center">
                <Typography font="heavy" size="lg">
                  {t('noExceptionsAtMoment')}
                </Typography>
              </UIBox>
            </>
          ) : (
            <UIBox width="100%" p={3} justifyContent="space-between">
              <Typography font="heavy" size="lg">
                {t('soldItemsToCheck')}
              </Typography>
              <UIButtonWithIcon
                label={t('refresh')}
                startIcon={<RefreshIcon />}
                onClick={handleRefreshClick}
              />
            </UIBox>
          )}
          {exceptionsNotManaged.length > 0 && (
            <ProductList
              title={`${t('exceptionsCannotBeManaged')}`}
              type={ListType.NOT_MANAGED}
              products={exceptionsNotManaged}
              productsCount={exceptionsNotManaged.reduce(
                (a, b) => a + (b.soldItems || 0),
                0
              )}
              notManagedList={{
                onRemove:
                  selectedUPC.notManaged.length ===
                  exceptionsUPC.notManaged.length
                    ? (): void => setConfirmDeleteExceptions('notManaged')
                    : handleRemoveNotManagedItemClick,
              }}
            />
          )}
          {exceptionsManaged.length > 0 && (
            <ProductList
              title={`${t('exceptionsCanBeManaged')} (${t('epcTaggedSold')})`}
              type={ListType.MANAGED}
              products={exceptionsManaged}
              managedList={{
                onConfirm: (): void => setConfirmModalVisibility(true),
                onRemove:
                  selectedUPC.managed.length === exceptionsUPC.managed.length
                    ? (): void => setConfirmDeleteExceptions('managed')
                    : handleRemoveManagedItemClick,
              }}
              productsCount={exceptionsManaged.reduce(
                (a, b) => a + (b.soldItems || 0),
                0
              )}
            />
          )}
          <CTAContainer
            type="BACK"
            onClick={(): void => history.push(AppRoutes.SALE)}
          />
        </>
      )}
      <AlertSnackbar
        severity="success"
        message={t('modal.datasaved')}
        open={dataSaved}
        setIsOpen={setDataSaved}
        autoHideDuration={dataSavedSnackbarDuration}
        onClose={(): void => {
          dispatch(setDataSaved(false));
        }}
      />
      <ErrorSnackbar
        autoHideDuration={4000}
        open={getExceptionsError}
        setIsOpen={setGetExceptionsError}
        errorMessage={
          getSaleExceptionsHasError?.body?.errorMessage ||
          getSaleExceptionsHasError?.body ||
          t('error.getSaleExceptions')
        }
      />
      <ErrorSnackbar
        autoHideDuration={4000}
        open={removeItemError}
        setIsOpen={setRemoveItemError}
        errorMessage={
          removePosItemsHasError?.body?.errorMessage ||
          removePosItemsHasError?.body ||
          t('error.removePosItems')
        }
      />
      <ErrorSnackbar
        autoHideDuration={6000}
        open={confirmSoldItems.error && confirmSoldItems.errorCode !== 400}
        setIsOpen={confirmSoldItems.setError}
        errorMessage={confirmSoldItems.errorMessage}
      />
    </>
  );
};

export default PageSaleExceptions;
